@blue: #2C7FD8;
@purple: #914393;
@cyan: #13C2C2;
@green: #35B58B;
@magenta: #EB2F96;
@pink: #eb2f96;
@red: #EE3737;
@orange: #FA8C16;
@yellow: #E39424;
@volcano: #FA541C;
@geekblue: #2F54EB;
@gold: #FAAD14;
@lime: #A0D911;
@colorPrimary: #0095ff;
@colorSuccess: #35b58b;
@colorWarning: #e39424;
@colorError: #ee3737;
@colorInfo: #2c7fd8;
@colorLink: #2c7fd8;
@colorTextBase: #0C1720;
@colorBgBase: #f5f5f5;
@fontFamily: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
@fontFamilyCode: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
@fontSize: 14;
@lineWidth: 1;
@lineType: solid;
@motionUnit: 0.1;
@motionBase: 0;
@motionEaseOutCirc: cubic-bezier(0.08, 0.82, 0.17, 1);
@motionEaseInOutCirc: cubic-bezier(0.78, 0.14, 0.15, 0.86);
@motionEaseOut: cubic-bezier(0.215, 0.61, 0.355, 1);
@motionEaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
@motionEaseOutBack: cubic-bezier(0.12, 0.4, 0.29, 1.46);
@motionEaseInBack: cubic-bezier(0.71, -0.46, 0.88, 0.6);
@motionEaseInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
@motionEaseOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
@borderRadius: 8;
@sizeUnit: 4;
@sizeStep: 4;
@sizePopupArrow: 16;
@controlHeight: 32;
@zIndexBase: 0;
@zIndexPopupBase: 1000;
@opacityImage: 1;
@wireframe: false;
@motion: true;
@primary: #0095FF;
@black: #0C1720;
@grey: #B5B5B5;
@white: #ffffff;
@fontWeightStrong: 500;
@colorBgContainer: #ffffff;
@colorTextSecondary: rgba(12, 23, 32, 0.6);
@colorTextDescription: rgba(12, 23, 32, 0.6);
@colorTextDisabled: rgba(12, 23, 32, 0.6);
@fontSizeHeading1: 24;
@fontSizeHeading2: 20;
@fontSizeHeading3: 18;
@fontSizeHeading4: 16;
@fontSizeHeading5: 14;
@lineHeightHeading1: 1.3333333333333333;
@lineHeightHeading2: 1.4;
@lineHeightHeading3: 1.4444444444444444;
@lineHeightHeading4: 1.5;
@lineHeightHeading5: 1.5714285714285714;
@colorBgLayout: #f5f5f5;
@boxShadow: 0 1px 2px rgba(12, 23, 32, 0.05), 0 2px 4px rgba(12, 23, 32, 0.1);
@controlOutline: rgba(12, 23, 32, 0.05);
@controlOutlineWidth: 2;
@colorBgElevated: #ffffff;
@controlItemBgHover: #e5f5ff;
@controlItemBgActive: #f0faff;
@controlItemBgActiveHover: #e5f5ff;
@blue-1: #f0faff;
@blue1: #f0faff;
@blue-2: #d6efff;
@blue2: #d6efff;
@blue-3: #adddff;
@blue3: #adddff;
@blue-4: #7ebef2;
@blue4: #7ebef2;
@blue-5: #539fe6;
@blue5: #539fe6;
@blue-6: #2c7fd8;
@blue6: #2c7fd8;
@blue-7: #1b5fb3;
@blue7: #1b5fb3;
@blue-8: #0e438c;
@blue8: #0e438c;
@blue-9: #052a66;
@blue9: #052a66;
@blue-10: #031840;
@blue10: #031840;
@purple-1: #d4c7d2;
@purple1: #d4c7d2;
@purple-2: #c7bbc6;
@purple2: #c7bbc6;
@purple-3: #baafb9;
@purple3: #baafb9;
@purple-4: #ad87ab;
@purple4: #ad87ab;
@purple-5: #a164a0;
@purple5: #a164a0;
@purple-6: #914393;
@purple6: #914393;
@purple-7: #6a2d6e;
@purple7: #6a2d6e;
@purple-8: #441a47;
@purple8: #441a47;
@purple-9: #1e0a21;
@purple9: #1e0a21;
@purple-10: #000000;
@purple10: #000000;
@cyan-1: #e6fffb;
@cyan1: #e6fffb;
@cyan-2: #b5f5ec;
@cyan2: #b5f5ec;
@cyan-3: #87e8de;
@cyan3: #87e8de;
@cyan-4: #5cdbd3;
@cyan4: #5cdbd3;
@cyan-5: #36cfc9;
@cyan5: #36cfc9;
@cyan-6: #13c2c2;
@cyan6: #13c2c2;
@cyan-7: #08979c;
@cyan7: #08979c;
@cyan-8: #006d75;
@cyan8: #006d75;
@cyan-9: #00474f;
@cyan9: #00474f;
@cyan-10: #002329;
@cyan10: #002329;
@green-1: #e6f5ed;
@green1: #e6f5ed;
@green-2: #d8e8e0;
@green2: #d8e8e0;
@green-3: #a9dbc5;
@green3: #a9dbc5;
@green-4: #7ecfae;
@green4: #7ecfae;
@green-5: #57c29b;
@green5: #57c29b;
@green-6: #35b58b;
@green6: #35b58b;
@green-7: #228f6e;
@green7: #228f6e;
@green-8: #146952;
@green8: #146952;
@green-9: #094235;
@green9: #094235;
@green-10: #041c17;
@green10: #041c17;
@magenta-1: #fff0f6;
@magenta1: #fff0f6;
@magenta-2: #ffd6e7;
@magenta2: #ffd6e7;
@magenta-3: #ffadd2;
@magenta3: #ffadd2;
@magenta-4: #ff85c0;
@magenta4: #ff85c0;
@magenta-5: #f759ab;
@magenta5: #f759ab;
@magenta-6: #eb2f96;
@magenta6: #eb2f96;
@magenta-7: #c41d7f;
@magenta7: #c41d7f;
@magenta-8: #9e1068;
@magenta8: #9e1068;
@magenta-9: #780650;
@magenta9: #780650;
@magenta-10: #520339;
@magenta10: #520339;
@pink-1: #fff0f6;
@pink1: #fff0f6;
@pink-2: #ffd6e7;
@pink2: #ffd6e7;
@pink-3: #ffadd2;
@pink3: #ffadd2;
@pink-4: #ff85c0;
@pink4: #ff85c0;
@pink-5: #f759ab;
@pink5: #f759ab;
@pink-6: #eb2f96;
@pink6: #eb2f96;
@pink-7: #c41d7f;
@pink7: #c41d7f;
@pink-8: #9e1068;
@pink8: #9e1068;
@pink-9: #780650;
@pink9: #780650;
@pink-10: #520339;
@pink10: #520339;
@red-1: #fff2f0;
@red1: #fff2f0;
@red-2: #ffe2de;
@red2: #ffe2de;
@red-3: #ffbcb5;
@red3: #ffbcb5;
@red-4: #ff948c;
@red4: #ff948c;
@red-5: #fa6761;
@red5: #fa6761;
@red-6: #ee3737;
@red6: #ee3737;
@red-7: #c72429;
@red7: #c72429;
@red-8: #a1151e;
@red8: #a1151e;
@red-9: #7a0a15;
@red9: #7a0a15;
@red-10: #540610;
@red10: #540610;
@orange-1: #fff7e6;
@orange1: #fff7e6;
@orange-2: #ffe7ba;
@orange2: #ffe7ba;
@orange-3: #ffd591;
@orange3: #ffd591;
@orange-4: #ffc069;
@orange4: #ffc069;
@orange-5: #ffa940;
@orange5: #ffa940;
@orange-6: #fa8c16;
@orange6: #fa8c16;
@orange-7: #d46b08;
@orange7: #d46b08;
@orange-8: #ad4e00;
@orange8: #ad4e00;
@orange-9: #873800;
@orange9: #873800;
@orange-10: #612500;
@orange10: #612500;
@yellow-1: #fffbf0;
@yellow1: #fffbf0;
@yellow-2: #fff1cc;
@yellow2: #fff1cc;
@yellow-3: #ffe2a3;
@yellow3: #ffe2a3;
@yellow-4: #fccf79;
@yellow4: #fccf79;
@yellow-5: #f0b14d;
@yellow5: #f0b14d;
@yellow-6: #e39424;
@yellow6: #e39424;
@yellow-7: #bd7115;
@yellow7: #bd7115;
@yellow-8: #965209;
@yellow8: #965209;
@yellow-9: #703701;
@yellow9: #703701;
@yellow-10: #4a2100;
@yellow10: #4a2100;
@volcano-1: #fff2e8;
@volcano1: #fff2e8;
@volcano-2: #ffd8bf;
@volcano2: #ffd8bf;
@volcano-3: #ffbb96;
@volcano3: #ffbb96;
@volcano-4: #ff9c6e;
@volcano4: #ff9c6e;
@volcano-5: #ff7a45;
@volcano5: #ff7a45;
@volcano-6: #fa541c;
@volcano6: #fa541c;
@volcano-7: #d4380d;
@volcano7: #d4380d;
@volcano-8: #ad2102;
@volcano8: #ad2102;
@volcano-9: #871400;
@volcano9: #871400;
@volcano-10: #610b00;
@volcano10: #610b00;
@geekblue-1: #f0f5ff;
@geekblue1: #f0f5ff;
@geekblue-2: #d6e4ff;
@geekblue2: #d6e4ff;
@geekblue-3: #adc6ff;
@geekblue3: #adc6ff;
@geekblue-4: #85a5ff;
@geekblue4: #85a5ff;
@geekblue-5: #597ef7;
@geekblue5: #597ef7;
@geekblue-6: #2f54eb;
@geekblue6: #2f54eb;
@geekblue-7: #1d39c4;
@geekblue7: #1d39c4;
@geekblue-8: #10239e;
@geekblue8: #10239e;
@geekblue-9: #061178;
@geekblue9: #061178;
@geekblue-10: #030852;
@geekblue10: #030852;
@gold-1: #fffbe6;
@gold1: #fffbe6;
@gold-2: #fff1b8;
@gold2: #fff1b8;
@gold-3: #ffe58f;
@gold3: #ffe58f;
@gold-4: #ffd666;
@gold4: #ffd666;
@gold-5: #ffc53d;
@gold5: #ffc53d;
@gold-6: #faad14;
@gold6: #faad14;
@gold-7: #d48806;
@gold7: #d48806;
@gold-8: #ad6800;
@gold8: #ad6800;
@gold-9: #874d00;
@gold9: #874d00;
@gold-10: #613400;
@gold10: #613400;
@lime-1: #fcffe6;
@lime1: #fcffe6;
@lime-2: #f4ffb8;
@lime2: #f4ffb8;
@lime-3: #eaff8f;
@lime3: #eaff8f;
@lime-4: #d3f261;
@lime4: #d3f261;
@lime-5: #bae637;
@lime5: #bae637;
@lime-6: #a0d911;
@lime6: #a0d911;
@lime-7: #7cb305;
@lime7: #7cb305;
@lime-8: #5b8c00;
@lime8: #5b8c00;
@lime-9: #3f6600;
@lime9: #3f6600;
@lime-10: #254000;
@lime10: #254000;
@colorText: rgba(12, 23, 32, 0.88);
@colorTextTertiary: rgba(12, 23, 32, 0.45);
@colorTextQuaternary: rgba(12, 23, 32, 0.25);
@colorFill: rgba(12, 23, 32, 0.15);
@colorFillSecondary: rgba(12, 23, 32, 0.06);
@colorFillTertiary: rgba(12, 23, 32, 0.04);
@colorFillQuaternary: rgba(12, 23, 32, 0.02);
@colorBgSpotlight: rgba(12, 23, 32, 0.85);
@colorBorder: #cfcfcf;
@colorBorderSecondary: #e6e6e6;
@colorPrimaryBg: #e6f9ff;
@colorPrimaryBgHover: #a3e5ff;
@colorPrimaryBorder: #7ad5ff;
@colorPrimaryBorderHover: #52c2ff;
@colorPrimaryHover: #29adff;
@colorPrimaryActive: #0077d9;
@colorPrimaryTextHover: #29adff;
@colorPrimaryText: #0095ff;
@colorPrimaryTextActive: #0077d9;
@colorSuccessBg: #e6f5ed;
@colorSuccessBgHover: #d8e8e0;
@colorSuccessBorder: #a9dbc5;
@colorSuccessBorderHover: #7ecfae;
@colorSuccessHover: #7ecfae;
@colorSuccessActive: #228f6e;
@colorSuccessTextHover: #57c29b;
@colorSuccessText: #35b58b;
@colorSuccessTextActive: #228f6e;
@colorErrorBg: #fff2f0;
@colorErrorBgHover: #ffe2de;
@colorErrorBorder: #ffbcb5;
@colorErrorBorderHover: #ff948c;
@colorErrorHover: #fa6761;
@colorErrorActive: #c72429;
@colorErrorTextHover: #fa6761;
@colorErrorText: #ee3737;
@colorErrorTextActive: #c72429;
@colorWarningBg: #fffbf0;
@colorWarningBgHover: #fff1cc;
@colorWarningBorder: #ffe2a3;
@colorWarningBorderHover: #fccf79;
@colorWarningHover: #fccf79;
@colorWarningActive: #bd7115;
@colorWarningTextHover: #f0b14d;
@colorWarningText: #e39424;
@colorWarningTextActive: #bd7115;
@colorInfoBg: #f0faff;
@colorInfoBgHover: #d6efff;
@colorInfoBorder: #adddff;
@colorInfoBorderHover: #7ebef2;
@colorInfoHover: #7ebef2;
@colorInfoActive: #1b5fb3;
@colorInfoTextHover: #539fe6;
@colorInfoText: #2c7fd8;
@colorInfoTextActive: #1b5fb3;
@colorLinkHover: #7ebef2;
@colorLinkActive: #1b5fb3;
@colorBgMask: rgba(0, 0, 0, 0.45);
@colorWhite: #fff;
@fontSizeSM: 12;
@fontSizeLG: 16;
@fontSizeXL: 20;
@lineHeight: 1.5714285714285714;
@lineHeightLG: 1.5;
@lineHeightSM: 1.6666666666666667;
@sizeXXL: 48;
@sizeXL: 32;
@sizeLG: 24;
@sizeMD: 20;
@sizeMS: 16;
@size: 16;
@sizeSM: 12;
@sizeXS: 8;
@sizeXXS: 4;
@controlHeightSM: 24;
@controlHeightXS: 16;
@controlHeightLG: 40;
@motionDurationFast: 0.1s;
@motionDurationMid: 0.2s;
@motionDurationSlow: 0.3s;
@lineWidthBold: 2;
@borderRadiusXS: 2;
@borderRadiusSM: 6;
@borderRadiusLG: 10;
@borderRadiusOuter: 6;
@colorFillContent: rgba(12, 23, 32, 0.06);
@colorFillContentHover: rgba(12, 23, 32, 0.15);
@colorFillAlter: rgba(12, 23, 32, 0.02);
@colorBgContainerDisabled: rgba(12, 23, 32, 0.04);
@colorBorderBg: #ffffff;
@colorSplit: rgba(5, 5, 5, 0.1);
@colorTextPlaceholder: rgba(12, 23, 32, 0.25);
@colorTextHeading: rgba(12, 23, 32, 0.88);
@colorTextLabel: rgba(12, 23, 32, 0.6);
@colorTextLightSolid: #fff;
@colorHighlight: #ee3737;
@colorBgTextHover: rgba(12, 23, 32, 0.06);
@colorBgTextActive: rgba(12, 23, 32, 0.15);
@colorIcon: rgba(12, 23, 32, 0.45);
@colorIconHover: rgba(12, 23, 32, 0.88);
@colorErrorOutline: rgba(255, 38, 5, 0.06);
@colorWarningOutline: rgba(255, 188, 5, 0.06);
@fontSizeIcon: 12;
@lineWidthFocus: 4;
@controlInteractiveSize: 16;
@controlItemBgActiveDisabled: rgba(12, 23, 32, 0.15);
@controlTmpOutline: rgba(12, 23, 32, 0.02);
@opacityLoading: 0.65;
@linkDecoration: none;
@linkHoverDecoration: none;
@linkFocusDecoration: none;
@controlPaddingHorizontal: 12;
@controlPaddingHorizontalSM: 8;
@paddingXXS: 4;
@paddingXS: 8;
@paddingSM: 12;
@padding: 16;
@paddingMD: 20;
@paddingLG: 24;
@paddingXL: 32;
@paddingContentHorizontalLG: 24;
@paddingContentVerticalLG: 16;
@paddingContentHorizontal: 16;
@paddingContentVertical: 12;
@paddingContentHorizontalSM: 16;
@paddingContentVerticalSM: 8;
@marginXXS: 4;
@marginXS: 8;
@marginSM: 12;
@margin: 16;
@marginMD: 20;
@marginLG: 24;
@marginXL: 32;
@marginXXL: 48;
@boxShadowSecondary: 
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05)
    ;
@boxShadowTertiary: 
      0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02),
      0 2px 4px 0 rgba(0, 0, 0, 0.02)
    ;
@screenXS: 480;
@screenXSMin: 480;
@screenXSMax: 575;
@screenSM: 576;
@screenSMMin: 576;
@screenSMMax: 767;
@screenMD: 768;
@screenMDMin: 768;
@screenMDMax: 991;
@screenLG: 992;
@screenLGMin: 992;
@screenLGMax: 1199;
@screenXL: 1200;
@screenXLMin: 1200;
@screenXLMax: 1599;
@screenXXL: 1600;
@screenXXLMin: 1600;
@boxShadowPopoverArrow: 2px 2px 5px rgba(0, 0, 0, 0.05);
@boxShadowCard: 
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09)
    ;
@boxShadowDrawerRight: 
      -6px 0 16px 0 rgba(0, 0, 0, 0.08),
      -3px 0 6px -4px rgba(0, 0, 0, 0.12),
      -9px 0 28px 8px rgba(0, 0, 0, 0.05)
    ;
@boxShadowDrawerLeft: 
      6px 0 16px 0 rgba(0, 0, 0, 0.08),
      3px 0 6px -4px rgba(0, 0, 0, 0.12),
      9px 0 28px 8px rgba(0, 0, 0, 0.05)
    ;
@boxShadowDrawerUp: 
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05)
    ;
@boxShadowDrawerDown: 
      0 -6px 16px 0 rgba(0, 0, 0, 0.08),
      0 -3px 6px -4px rgba(0, 0, 0, 0.12),
      0 -9px 28px 8px rgba(0, 0, 0, 0.05)
    ;
@boxShadowTabsOverflowLeft: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
@boxShadowTabsOverflowRight: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
@boxShadowTabsOverflowTop: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
@boxShadowTabsOverflowBottom: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);

@import "~antd/dist/reset.css";

html,
body {
  scroll-behavior: smooth;
  border: 0;
  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  color: @colorText;
  font-size: @fontSize * 1px;
  line-height: @lineHeight;
  font-family: @fontFamily;
  background-color: @colorBgBase;
  width: 100%;
  height: 100%;
}

ol,
dl,
ul {
  padding-inline-start: @paddingMD * 1px;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 2px solid rgba(200, 200, 200, 0.2);
  transition: all 0.2s ease;

  background: rgba(150, 150, 150, 0.4);
  &:hover {
    background: rgba(150, 150, 150, 0.6);
  }
  &:window-inactive {
    background: rgba(150, 150, 150, 0.1);
  }
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../assets/fonts/ProximaNovaRegular/font.woff") format("woff"), url("../assets/fonts/ProximaNovaRegular/font.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../assets/fonts/ProximaNovaRegularIt/font.woff") format("woff"), url("../assets/fonts/ProximaNovaRegularIt/font.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

// medium
@font-face {
  font-family: "Proxima Nova";
  src: url("../assets/fonts/ProximaNovaMedium/font.woff") format("woff"), url("../assets/fonts/ProximaNovaMedium/font.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

// bold
@font-face {
  font-family: "Proxima Nova";
  src: url("../assets/fonts/ProximaNovaSemibold/font.woff") format("woff"), url("../assets/fonts/ProximaNovaSemibold/font.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

pre {
  font-size: @fontSizeSM * 1px;
  padding: @paddingXS * 1px;
  background-color: @colorBgLayout;
  border-radius: @borderRadius * 1px;
  border: 1px solid @colorBorder;
}
